import React from "react";
import { Routes, Route } from "react-router-dom";

// Layout
import Layout from "./Layout";

// Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Donate from "./pages/Donate";
import Testimonials from "./pages/Testimonials";
import NoPage from "./pages/NoPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="donate" element={<Donate />} />
        <Route path="about" element={<About />} />
        <Route path="testimonials" element={<Testimonials />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
}

export default App;
