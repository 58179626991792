import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      {/* Hero Section */}
      <div className="position-relative">
        <div
          className="bg-image"
          style={{
            backgroundImage: "url('/images/house.jpg')",
            height: "70vh",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <div
            className="position-absolute top-0 start-0 w-100 h-100"
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          ></div>
          <Container className="position-relative h-100 d-flex flex-column justify-content-center">
            <Row className="justify-content-center text-center">
              <Col md={8} className="text-white">
                <h1 className="display-3 fw-bold mb-4">
                  Faith Home Restoration
                </h1>
                <p className="lead fs-4 mb-5">
                  Rebuilding lives through Christ-centered recovery and hope
                </p>
                <div className="d-flex justify-content-center gap-3">
                  <Button
                    as={Link}
                    to="/about"
                    variant="primary"
                    size="lg"
                    className="px-4 py-3 fw-semibold"
                  >
                    Learn More
                  </Button>
                  <Button
                    as={Link}
                    to="/donate"
                    variant="outline-light"
                    size="lg"
                    className="px-4 py-3 fw-semibold"
                  >
                    Support Our Mission
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* Mission Section */}
      <section className="py-5">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col md={8} className="text-center">
              <h2 className="fw-bold mb-4">Our Mission</h2>
              <p className="lead">
                Faith Home Restoration provides a safe, structured environment
                for women seeking recovery from substance abuse through a
                Christ-centered approach.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm border-0">
                <Card.Img
                  variant="top"
                  src="https://images.unsplash.com/photo-1531983412531-1f49a365ffed?q=80&w=600&auto=format&fit=crop"
                  alt="Faith-based support"
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="fw-bold text-primary">
                    Faith
                  </Card.Title>
                  <Card.Text>
                    We believe in the transformative power of faith in Christ as
                    the foundation for lasting recovery and life change.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm border-0">
                <Card.Img
                  variant="top"
                  src="https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?q=80&w=600&auto=format&fit=crop"
                  alt="Recovery support"
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="fw-bold text-primary">
                    Recovery
                  </Card.Title>
                  <Card.Text>
                    Our structured program addresses addiction through
                    counseling, education, and community support.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm border-0">
                <Card.Img
                  variant="top"
                  src="https://images.unsplash.com/photo-1470472304068-4398a9daab00?q=80&w=600&auto=format&fit=crop"
                  alt="Life restoration"
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="fw-bold text-primary">
                    Restoration
                  </Card.Title>
                  <Card.Text>
                    We help women rebuild their lives, develop life skills, and
                    prepare for a future filled with purpose.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Program Highlights */}
      <section className="bg-light py-5">
        <Container>
          <Row className="align-items-center mb-5">
            <Col lg={6} className="mb-4 mb-lg-0">
              <img
                src="https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?q=80&w=800&auto=format&fit=crop"
                alt="Women in recovery program"
                className="img-fluid rounded shadow"
                style={{ objectFit: "cover", width: "100%" }}
              />
            </Col>
            <Col lg={6}>
              <h2 className="fw-bold mb-4">Our Program</h2>
              <p className="lead mb-4">
                Faith Home Restoration offers a comprehensive recovery program
                designed to address the spiritual, emotional, and practical
                needs of women seeking freedom from addiction.
              </p>
              <div className="d-flex mb-3">
                <div className="me-3 text-primary">
                  <i className="bi bi-book-fill fs-4"></i>
                </div>
                <div>
                  <h5 className="mb-2">Bible Study & Prayer</h5>
                  <p>Daily devotionals and spiritual growth opportunities</p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="me-3 text-primary">
                  <i className="bi bi-people-fill fs-4"></i>
                </div>
                <div>
                  <h5 className="mb-2">Supportive Community</h5>
                  <p>Living alongside others who understand the journey</p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="me-3 text-primary">
                  <i className="bi bi-briefcase-fill fs-4"></i>
                </div>
                <div>
                  <h5 className="mb-2">Life Skills Development</h5>
                  <p>Learning practical skills for sustainable independence</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonials Preview Section */}
      <section className="py-5">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col md={8} className="text-center">
              <h2 className="fw-bold mb-4">Lives Transformed</h2>
              <p className="lead">
                Hear from women whose lives have been changed through our
                program
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card className="border-0 shadow-sm mb-4">
                <Card.Body className="p-4">
                  <Row>
                    <Col md={3} className="text-center mb-3 mb-md-0">
                      <img
                        src="/images/stacie.png"
                        alt="Testimonial from Stacie"
                        className="rounded-circle mb-3"
                        style={{
                          width: "120px",
                          height: "120px",
                          objectFit: "cover",
                        }}
                      />
                      <h5 className="fw-bold mb-0">Stacie</h5>
                      <p className="text-muted">Resident</p>
                    </Col>
                    <Col md={9}>
                      <blockquote className="border-start ps-4 border-primary border-3">
                        <p className="mb-3 fst-italic">
                          &ldquo;I have been at Faith Home for a year and a
                          half, and my life has changed so much for the better.
                          I&apos;ve developed a close relationship with the
                          Lord, more profound than I ever imagined. Being at
                          Faith Home has allowed me to truly understand what it
                          means to have people in my life who love me.&rdquo;
                        </p>
                      </blockquote>
                      <Button
                        as={Link}
                        to="/testimonials"
                        variant="outline-primary"
                        size="sm"
                      >
                        Read Full Story{" "}
                        <i className="bi bi-arrow-right ms-1"></i>
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="text-center">
              <Button as={Link} to="/testimonials" variant="primary">
                Read More Testimonials
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
