import React from "react";
import LazyLoad from "react-lazyload";
import { Container, Row, Col, Card } from "react-bootstrap";

export default function Testimonials() {
  return (
    <section className="bg-light py-5">
      <Container className="text-center">
        <Row className="justify-content-center mb-5">
          <Col lg={8}>
            <h2 className="display-4 fw-bold mb-4">Testimonials</h2>
            <h3 className="fw-semibold text-secondary mb-3">Psalm 66:16</h3>
            <p className="lead fst-italic mb-5">
              &ldquo;Come and hear, all you who fear God, and I will tell what
              he has done for my soul.&rdquo;
            </p>
          </Col>
        </Row>

        <Row className="g-4 mb-5">
          {/* Testimonial 1 - Stacie */}
          <Col lg={6}>
            <Card className="h-100 shadow border-0 p-4 testimonial-card">
              <Card.Body className="d-flex flex-column">
                <blockquote className="mb-4">
                  <p className="text-start mb-3">
                    I have been at Faith Home for a year and a half, and my life
                    has changed so much for the better. I&apos;ve developed a
                    close relationship with the Lord, more profound than I ever
                    imagined. Being at Faith Home has allowed me to truly
                    understand what it means to have people in my life who love
                    me. The other women here are like my sisters, and Amy is
                    amazing—she radiates the Lord in everything she does.
                  </p>
                  <p className="text-start mb-3">
                    Faith Home provides numerous resources, including clothes,
                    food, financial aid, and much more. I love living here, and
                    I&apos;m incredibly grateful for this chapter in my life.
                    I&apos;m happier than I&apos;ve ever been because I have a
                    newfound peace since learning how much the Lord loves me. I
                    never felt worthy before, but I do now. This feels like my
                    home for now, and I love helping out in any way I can.
                  </p>
                  <p className="text-start mb-3">
                    I highly recommend Faith Home to anyone interested in coming
                    here. I am very, very grateful to Amy and the board for all
                    their help. The support here is beyond what I ever thought
                    was available. I can&apos;t say enough about this home.
                  </p>
                </blockquote>
                <div className="mt-auto d-flex align-items-center">
                  <LazyLoad height={80}>
                    <img
                      src="/images/stacie.png"
                      alt="Testimonial from Stacie"
                      className="rounded-circle me-3"
                      style={{
                        width: "64px",
                        height: "64px",
                        objectFit: "cover",
                      }}
                    />
                  </LazyLoad>
                  <div className="text-start">
                    <div className="fw-bold">Stacie</div>
                    <div className="text-secondary small">Resident</div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Testimonial 2 - Rachel */}
          <Col lg={6}>
            <Card className="h-100 shadow border-0 p-4 testimonial-card">
              <Card.Body className="d-flex flex-column">
                <blockquote className="mb-4">
                  <p className="text-start mb-3">
                    I was in a terrible place when I came to Faith Home. It was
                    a Godsend and truly saved my life. Faith Home has allowed me
                    to draw closer to God and become the person He intended me
                    to be. I&apos;m incredibly thankful that Faith Home was here
                    to help me get my life together. I don&apos;t know what I
                    would have done without these women.
                  </p>
                  <p className="text-start mb-3">
                    Amy is an amazing mentor, and she has taught me so many
                    things, including how to pray. I believe that God led me
                    here, shaping me into the woman He meant me to be. Faith
                    Home has been instrumental in my journey of transformation,
                    and I am profoundly grateful.
                  </p>
                </blockquote>
                <div className="mt-auto d-flex align-items-center">
                  <LazyLoad height={80}>
                    <img
                      src="/images/rachel.png"
                      alt="Testimonial from Rachel"
                      className="rounded-circle me-3"
                      style={{
                        width: "64px",
                        height: "64px",
                        objectFit: "cover",
                      }}
                    />
                  </LazyLoad>
                  <div className="text-start">
                    <div className="fw-bold">Rachel</div>
                    <div className="text-secondary small">Resident</div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Testimonial 3 - Toni */}
          <Col lg={6}>
            <Card className="h-100 shadow border-0 p-4 testimonial-card">
              <Card.Body className="d-flex flex-column">
                <blockquote className="mb-4">
                  <p className="text-start mb-3">
                    Over the last 24 years, I have been on a journey filled with
                    both ups and downs. It was during the lows in my life that I
                    reached out to God. My relationship with God was scattered
                    at best, and deep down, I had a thirst to learn more about
                    Christianity. However, every time I tried to grow in my
                    relationship with Christ, the devil wreaked havoc in my
                    life, which in turn scared me away from learning more about
                    my God.
                  </p>
                  <p className="text-start mb-3">
                    I was not equipped with the knowledge or spiritual warfare
                    abilities needed to move forward in Christ. Only through the
                    power of Jesus and my growing faith in this past year have I
                    been able to build my relationship with the most important
                    and miraculous being—my God, Jesus Christ, the Faithful Son,
                    and the Holy Spirit.
                  </p>
                </blockquote>
                <div className="mt-auto d-flex align-items-center">
                  <LazyLoad height={80}>
                    <img
                      src="/images/toni.png"
                      alt="Testimonial from Toni"
                      className="rounded-circle me-3"
                      style={{
                        width: "64px",
                        height: "64px",
                        objectFit: "cover",
                      }}
                    />
                  </LazyLoad>
                  <div className="text-start">
                    <div className="fw-bold">Toni</div>
                    <div className="text-secondary small">Resident</div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Testimonial 4 - Kristi */}
          <Col lg={6}>
            <Card className="h-100 shadow border-0 p-4 testimonial-card">
              <Card.Body className="d-flex flex-column">
                <blockquote className="mb-4">
                  <p className="text-start mb-3">
                    The Lord has profoundly transformed my life. Through Him, I
                    now have a home to find stillness, and God has blessed me
                    with a good job. I&apos;ve rebuilt relationships with family
                    and friends. I am deeply grateful to God and Faith Home for
                    providing me with this time and place to grow and become a
                    woman of integrity. Most importantly, I am thankful for my
                    relationship with Jesus Christ, my Savior. The opportunity
                    and blessing that this home is are truly unique!
                  </p>
                  <p className="text-start mb-3">
                    Unfortunately, in my fleshly state, I had placed God on the
                    back burner. Today, He is front and center in my life. He
                    was always there – He never left. It was me who left and was
                    running away from my Lord and Savior. What a blessing it is
                    that He never gave up on me, even after I had given up on
                    myself. Faith Home has become a place to rest, repent, and
                    reconnect with Jesus. With love in my heart.
                  </p>
                </blockquote>
                <div className="mt-auto d-flex align-items-center">
                  <LazyLoad height={80}>
                    <img
                      src="/images/kristi.png"
                      alt="Testimonial from Kristi"
                      className="rounded-circle me-3"
                      style={{
                        width: "64px",
                        height: "64px",
                        objectFit: "cover",
                      }}
                    />
                  </LazyLoad>
                  <div className="text-start">
                    <div className="fw-bold">Kristi</div>
                    <div className="text-secondary small">Resident</div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Testimonial 5 - McKenna */}
          <Col lg={12}>
            <Card className="h-100 shadow border-0 p-4 testimonial-card">
              <Card.Body className="d-flex flex-column">
                <blockquote className="mb-4">
                  <p className="text-start mb-3">
                    I was born and raised in a Christian family here in Austin,
                    Texas, with a mom, a dad, and a little brother born just two
                    years after me. My childhood took an unexpected and tragic
                    turn when my father passed away right as I entered high
                    school at only 14 years old. From 14 to 18 years old, I
                    found myself on a dark and miserable journey leading nowhere
                    other than death. I abused substances, disobeyed my mother,
                    and lost faith in the Lord completely.
                  </p>
                  <p className="text-start mb-3">
                    After experiencing so much pain at such a young age and
                    going against everything I was taught growing up, I no
                    longer believed in God. At 18 years old, I found recovery,
                    and at 19, I gave birth to my beautiful daughter, Carter.
                    She brought me back to life, healing the shell of a person I
                    was as an adolescent. I worked to rebuild my relationship
                    with my mother, something I missed and longed for, healing a
                    part of my inner child in pain from the loss of my father.
                  </p>
                  <p className="text-start mb-3">
                    But something always felt empty in my heart; there was a
                    longing that was always present. Over the last year, I have
                    studied the Bible, prayed, and found the Lord again. It
                    hasn&apos;t always been an easy road, but it has always been
                    worth it. I have complete faith in the Lord regarding all
                    aspects of my life. I credit Jesus Christ not only for where
                    I am today as a daughter, sister, and mother but simply for
                    me being alive and feeling fulfilled. I no longer have that
                    hole in my heart. I feel joy and peace in times I once felt
                    empty and lonely.
                  </p>
                  <p className="text-start mb-3">
                    At the age of 22, I submitted myself to the Lord, and He has
                    provided beyond what I could have ever hoped or imagined. I
                    have infinite love and admiration for my mother, who is my
                    best friend, Amy Crawford, Beth Neely, and my amazing
                    daughter. Though she is only 3 and a half years old, she has
                    changed me in indescribable ways. I outwardly want to
                    express my love for the Lord and my faithfulness in His
                    word. My love for Him will always serve as a reminder that I
                    was brought out of the pits of hell by my faith in Jesus
                    Christ, and I do not have to go back there.
                  </p>
                </blockquote>
                <div className="mt-auto d-flex align-items-center">
                  <LazyLoad height={80}>
                    <img
                      src="/images/mckenna.png"
                      alt="Testimonial from McKenna"
                      className="rounded-circle me-3"
                      style={{
                        width: "64px",
                        height: "64px",
                        objectFit: "cover",
                      }}
                    />
                  </LazyLoad>
                  <div className="text-start">
                    <div className="fw-bold">McKenna</div>
                    <div className="text-secondary small">Resident</div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
