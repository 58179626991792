import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import LazyLoad from "react-lazyload";

export default function About() {
  // Define all styles as objects for inline usage
  const styles = {
    dividerCustom: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "1.25rem 0",
    },
    dividerCustomLine: {
      width: "100%",
      maxWidth: "7rem",
      height: "0.25rem",
      backgroundColor: "#dee2e6",
      borderRadius: "1rem",
    },
    dividerCustomIcon: {
      fontSize: "1.5rem",
      margin: "0 1rem",
      color: "#6c757d",
    },
    timeline: {
      position: "relative",
      padding: "20px 0",
    },
    timelineBadge: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      fontWeight: "bold",
      marginBottom: "15px",
    },
  };

  return (
    <div className="about-page">
      {/* Header Section - More subtle */}
      <section className="bg-light py-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={8}>
              <h1 className="display-4 fw-bold mb-4">
                About Faith Home Restoration
              </h1>
              <p className="lead mb-4 text-secondary">
                Guiding women through restoration with faith, hope, and
                compassion since 2013.
              </p>
            </Col>
            <Col lg={4} className="d-none d-lg-block">
              <LazyLoad height={300}>
                <img
                  src="/images/faith-home-building.jpg"
                  alt="Faith Home Building"
                  className="img-fluid rounded shadow-sm"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://via.placeholder.com/400x300?text=Faith+Home";
                  }}
                />
              </LazyLoad>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mission & Vision Section - Simplified cards */}
      <section className="py-5">
        <Container>
          <Row className="mb-5">
            <Col lg={6} className="mb-4 mb-lg-0">
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="p-4">
                  <h3 className="fw-bold mb-3">Our Mission</h3>
                  <Card.Text>
                    The mission of Faith Home Restoration is to guide women
                    through the restoration process with God, sharing our
                    experience, strength, and hope to encourage each woman every
                    step of the way. Our aim is to provide them with a safe
                    place they can call home.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="p-4">
                  <h3 className="fw-bold mb-3">Our Vision</h3>
                  <Card.Text>
                    Faith Home Restoration is dedicated to expanding its reach
                    and providing support for more women in need. Our goal is to
                    empower those within our home to become employable, active
                    members of society—individuals who walk in faith and the
                    fullness of God.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Leadership Section - Simplified */}
      <section className="py-5 bg-light">
        <Container>
          <Row className="mb-4">
            <Col className="text-center">
              <h2 className="fw-bold mb-3">Our Leadership</h2>
              <hr className="mx-auto" style={{ maxWidth: "100px" }} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={7}>
              {" "}
              {/* Increased from md={8} lg={6} xl={5} */}
              <Card className="border-0 shadow-sm overflow-hidden">
                <Row className="g-0">
                  <Col md={4}>
                    {" "}
                    {/* Decreased from md={5} to give more space to the content */}
                    <LazyLoad height={200}>
                      <img
                        className="w-100 h-100 object-fit-cover"
                        src="/images/amy.png"
                        alt="Amy Crawford"
                        style={{ objectFit: "cover" }}
                      />
                    </LazyLoad>
                  </Col>
                  <Col md={8}>
                    {" "}
                    {/* Increased from md={7} to make the content area wider */}
                    <Card.Body className="p-4">
                      <h4 className="fw-bold mb-1">Amy Crawford</h4>
                      <p className="text-muted mb-3">
                        House Manager & Administrator
                      </p>
                      <hr className="my-3" />
                      <div className="mb-2">
                        <i className="bi bi-telephone me-2"></i>
                        <span>(512) 552-3259</span>
                      </div>
                      <div>
                        <i className="bi bi-envelope me-2"></i>
                        <a
                          href="mailto:amycrawford@faithhomerestoration.org"
                          className="text-decoration-none"
                        >
                          amycrawford@faithhomerestoration.org
                        </a>
                      </div>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* History Section - Simplified */}
      <section className="py-5">
        <Container>
          <Row className="mb-4">
            <Col>
              <h2 className="fw-bold mb-3">Our History</h2>
              <hr style={{ maxWidth: "100px" }} />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div style={styles.timeline}>
                <div className="mb-4">
                  <Card className="border-0 shadow-sm">
                    <Card.Body className="p-4">
                      <h4 className="fw-bold mb-3">
                        Faith Home Was Established
                      </h4>
                      <p>
                        Faith Home was established in August 2013, founded on
                        over 20 years of experience serving women and helping
                        them understand their responsibilities in society.
                      </p>
                    </Card.Body>
                  </Card>
                </div>
                <div className="mb-4">
                  <Card className="border-0 shadow-sm">
                    <Card.Body className="p-4">
                      <h4 className="fw-bold mb-3">
                        Dedicated Volunteers & Support
                      </h4>
                      <p className="mb-3">
                        Amy Crawford volunteers as the administrator overseeing
                        all daily activities and bills at Faith Home. In
                        addition to Amy, several other volunteers contribute by
                        offering prayer, mentoring, transportation, computer and
                        resume assistance, lawn maintenance, and addressing the
                        women&apos;s personal needs.
                      </p>
                      <p>
                        Our primary objective is to provide a goal plan to each
                        woman upon arrival, guiding them toward the ability to
                        achieve it.
                      </p>
                    </Card.Body>
                  </Card>
                </div>
                <div className="mb-4">
                  <Card className="border-0 shadow-sm">
                    <Card.Body className="p-4">
                      <h4 className="fw-bold mb-3">Our Support Structure</h4>
                      <p className="mb-0">
                        Our home represents our first leased property, rented
                        from Moxie Management, Inc. We depend on the rental
                        income from the residents we serve. Additionally, we
                        receive monetary support from Hill Country Bible Church,
                        along with charitable donations from private parties.
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Call to Action Section - More subtle */}
      <section className="py-5 bg-light text-center">
        <Container className="py-4">
          <h2 className="fw-bold mb-4">Join Us in Making a Difference</h2>
          <p className="text-muted mb-4">
            Your support helps us provide a safe home and restoration for women
            in need.
          </p>
          <Button variant="secondary" className="me-3">
            Donate Now
          </Button>
          <Button variant="outline-secondary">Volunteer</Button>
        </Container>
      </section>
    </div>
  );
}
