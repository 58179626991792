import { Outlet, Link } from "react-router-dom";
import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

export default function Layout() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        expanded={expanded}
        className="py-3"
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
            <img
              src="/images/logo.png"
              height="40"
              className="me-2"
              alt="Faith Home Restoration Logo"
            />
            <span className="fs-4 fw-semibold">Faith Home Restoration</span>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : true)}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                as={Link}
                to="/"
                className="nav-link-custom mx-1 position-relative"
                onClick={() => setExpanded(false)}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="https://docs.google.com/document/d/1uw-jh1Z7SSRskY8ICMrtQlWHQonsvww6og8G48Wsbfg/edit?usp=sharing"
                target="_blank"
                rel="noreferrer"
                className="nav-link-custom mx-1 position-relative"
              >
                Application
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/testimonials"
                className="nav-link-custom mx-1 position-relative"
                onClick={() => setExpanded(false)}
              >
                Testimonials
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/donate"
                className="nav-link-custom mx-1 position-relative"
                onClick={() => setExpanded(false)}
              >
                Donate
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="nav-link-custom mx-1 position-relative"
                onClick={() => setExpanded(false)}
              >
                About
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <main className="flex-grow-1 bg-light">
        <Outlet />
      </main>

      <footer className="bg-dark text-white py-4">
        <Container>
          <div className="row align-items-center">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              <span className="small">
                © {new Date().getFullYear()} Faith Home Restoration Inc. All
                Rights Reserved.
              </span>
            </div>
            <div className="col-md-6">
              <ul className="list-inline text-center text-md-end mb-0">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/faithhomerestoration/"
                    className="text-light me-3 text-decoration-none social-link"
                  >
                    <i className="bi bi-facebook me-1"></i> Facebook
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UCbOxN5LJ8-Pfw6FQHf5iG_A"
                    className="text-light me-3 text-decoration-none social-link"
                  >
                    <i className="bi bi-youtube me-1"></i> YouTube
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="mailto:board@faithhomerestoration.org"
                    className="text-light text-decoration-none social-link"
                  >
                    <i className="bi bi-envelope me-1"></i> Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </footer>

      {/* CSS for hover effects */}
      <style>
        {`
        .nav-link-custom {
          color: rgba(255, 255, 255, 0.85);
          transition: all 0.3s ease;
          padding: 0.5rem 0.75rem;
          border-radius: 4px;
          position: relative;
        }
        
        .nav-link-custom:hover {
          color: white;
          background-color: rgba(255, 255, 255, 0.1);
        }
        
        .nav-link-custom::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 2px;
          background-color: white;
          transition: all 0.3s ease;
        }
        
        .nav-link-custom:hover::after {
          width: 80%;
          left: 10%;
        }
        
        .social-link {
          transition: all 0.3s ease;
          padding: 0.25rem;
        }
        
        .social-link:hover {
          color: white !important;
          transform: translateY(-3px);
        }
        `}
      </style>
    </div>
  );
}
