import React from "react";
import { Container, Row, Col, Card, Button, ListGroup } from "react-bootstrap";

export default function Donate() {
  return (
    <div className="bg-light py-5">
      <Container>
        {/* Hero Section */}
        <Row className="mb-5 justify-content-center text-center">
          <Col lg={10}>
            <h1 className="display-4 fw-bold text-primary mb-4">
              Make a Difference Today
            </h1>
            <p className="lead mb-4">
              Your generous donation helps restore lives through Christ-centered
              recovery.
            </p>
            <Button
              href="https://www.aplos.com/aws/give/FaithHomeRestoration"
              variant="success"
              size="lg"
              className="px-5 py-3 mb-5 shadow-sm fw-bold"
            >
              <i className="bi bi-heart-fill me-2"></i> Donate Now
            </Button>
          </Col>
        </Row>

        {/* Sponsor a Resident Section */}
        <Row className="mb-5 align-items-center">
          <Col lg={6} className="mb-4 mb-lg-0">
            <img
              src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=1000&auto=format&fit=crop"
              alt="Supportive community"
              className="img-fluid rounded shadow-sm"
              style={{ objectFit: "cover", height: "400px", width: "100%" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://images.unsplash.com/photo-1469571486292-b53601010376?q=80&w=1000&auto=format&fit=crop";
              }}
            />
          </Col>
          <Col lg={6}>
            <div className="p-1 p-md-4">
              <h2 className="fw-bold mb-4">Sponsor a Resident</h2>
              <div className="mb-4">
                <p className="lead">
                  If you are interested in sponsoring a Resident, please take a
                  moment to review the details outlined below.
                </p>
                <Card className="border-primary mb-4 shadow-sm">
                  <Card.Body>
                    <Card.Title className="text-primary">
                      Monthly Support
                    </Card.Title>
                    <Card.Text>
                      The cost to support each resident is approximately{" "}
                      <strong>$750 per month</strong>. As residents become more
                      self-sufficient, they contribute towards this figure.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <p>
                  Depending on the resident&apos;s financial circumstances,
                  partial to temporary full scholarships may be available.
                  Kindly consider what the Lord leads you to contribute to help
                  support these residents on their journey back to a
                  Christ-centered life.
                </p>
              </div>
              <Button
                href="https://www.aplos.com/aws/give/FaithHomeRestoration"
                variant="outline-primary"
                size="lg"
                className="px-4"
              >
                Become a Sponsor
              </Button>
            </div>
          </Col>
        </Row>

        {/* Ongoing Needs Section with Additional Images */}
        <Row className="mb-5">
          <Col lg={12}>
            <div className="bg-white p-4 p-md-5 rounded shadow-sm">
              <h2 className="fw-bold text-primary mb-4">Ongoing Needs</h2>
              <Row>
                <Col md={6} className="mb-4">
                  <Card className="h-100 border-0 shadow-sm">
                    <Card.Header className="bg-primary text-white">
                      <h5 className="mb-0">Supplies & Essentials</h5>
                    </Card.Header>
                    <Card.Img
                      variant="top"
                      src="https://images.unsplash.com/photo-1593113646773-028c64a8f1b8?q=80&w=600&auto=format&fit=crop"
                      alt="Donation items"
                      style={{ height: "200px", objectFit: "cover" }}
                    />
                    <ListGroup variant="flush">
                      <ListGroup.Item className="d-flex align-items-center">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        Cleaning Supplies (trash bags, toilet paper, paper
                        towels, etc.)
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex align-items-center">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        Food items (meat, coffee, vegetables, canned goods,
                        etc.)
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex align-items-center">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        Women&apos;s clothing and shoes
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex align-items-center">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        Women&apos;s hygiene items
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex align-items-center">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        HEB or Walmart gift cards for personal needs
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>

                <Col md={6} className="mb-4">
                  <Card className="h-100 border-0 shadow-sm bg-light">
                    <Card.Body className="d-flex flex-column">
                      <h5 className="fw-bold mb-3">How to Donate Items</h5>
                      <div className="text-center mb-4">
                        <img
                          src="https://images.unsplash.com/photo-1532629345422-7515f3d16bb6?q=80&w=600&auto=format&fit=crop"
                          alt="Donation box"
                          className="img-fluid rounded mb-3"
                          style={{ maxHeight: "200px", objectFit: "cover" }}
                        />
                      </div>
                      <p>
                        You can drop off your donations at our facility during
                        office hours:
                      </p>
                      <ul>
                        <li>Monday - Friday: 9am - 5pm</li>
                        <li>Saturday: 10am - 2pm</li>
                      </ul>
                      <p className="mb-4">
                        For large donations or special arrangements, please
                        contact us:
                      </p>
                      <div className="mt-auto text-center">
                        <Button
                          href="mailto:board@faithhomerestoration.org"
                          variant="outline-secondary"
                          className="w-100"
                        >
                          <i className="bi bi-envelope me-2"></i>
                          Contact Us About Donations
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* Impact Section */}
        <Row className="text-center mb-5">
          <Col>
            <h2 className="fw-bold mb-4">Your Impact</h2>
            <p className="lead mb-5">
              Every donation makes a difference in the lives of our residents
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
